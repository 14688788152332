import React from "react";
import "./AboutMe.css";
import avatar from "../../images/avatarstudent.jpg";

const AboutMe = () => {
  return (
    <section className="aboutMe">
      <div className="aboutMe__wrapper">
        <h2 className="aboutMe__title-section">Студент</h2>
        <div className="navtab__line"></div>

        <div className="aboutMe__wrapper-profile">
          <div className="aboutMe__wrapper-text">
            <h3 className="aboutMe__title">Екатерина</h3>
            <h4 className="aboutMe__title-career">
              Фронтенд-разработчик, 21 год
            </h4>
            <p className="aboutMe__subtitle">В фронтенд-разработке я больше всего люблю работать с проектированием логики отображения данных, 
с чем очень сильно помогает React. Я обожаю React за его универсальность и возможность использования даже на других устройствах, за его гибкость 
и возможность использования других библиотек и инструментов и наконец-то за его классную читаемость кода!
<br />
В свободное время предпочитаю уделять время книгам: от технической литературы до фэнтези, а еще увлекаюсь любительским рисованием.</p>
            <a
              className="aboutMe__link-social"
              href="https://www.facebook.com/"
            >
              Facebook
            </a>
            <a
              className="aboutMe__link-social"
              href="https://github.com/Hitomizavr"
            >
              Github
            </a>
          </div>
          <div>
            <img
              className="aboutMe__avatar"
              alt="avatar student"
              src={avatar}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
